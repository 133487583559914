import React from 'react';
// import Directory from '../../components/directory/Directory.component'
// import DirectoryCategory from '../../components/directoryCategory/directoryCategory.component'
// import DirectoryState from '../../components/directory-state/directory-state.component'
// import DirectoryWives from '../../components/directory-wives/directory-wives.component'

// import RecentlyBuild from '../../components/recentlybuild/recentlybuild.component'
// import AboutUs from '../../components/aboutus/aboutus.component'

import { useNavigate } from 'react-router-dom';
import styles from './orders.module.css'


export default function Orderpage({ history }) {
    const navigate = useNavigate();
    return (
  <div style={{backgroundColor:'#f5f5f5',paddingBlock: 15}}>
    <div style={{backgroundColor:'#fff',padding:15}}>
    <div style={{backgroundColor:'#f5f5f5',padding:15}}>
Orders
</div>
    </div>
    
    </div>
    )
}
