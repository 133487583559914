import styled from 'styled-components'
import { Link } from 'react-router-dom'
import CustomButton from '../custom-button/custom-button.component'


export const HeaderContainer = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
`

export const LogoContainer = styled(Link)`
    height: 30%;
    width: 70px;
    padding: 25px;
    &:hover {
        transform: scale(1.1);
        transition: transform 200ms;
`

export const OptionsContainer = styled.div`
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export const Line =styled.div`
width: 100%;
height: 1px;
margin-bottom:20px;
background-color:#FFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

`


export const OptionLink = styled(Link)`
    padding: 10px 15px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
        transition: transform 200ms;
    }
    
`
export const SearchLink = styled(Link)`
width: 20rem;
height: 2rem;
padding: 0 1rem; 
border-radius: 2rem;
border: none;
transition: transform 0.1s ease-in-out;
`

export const StyledCustomButton = styled(CustomButton)`
margin-top: auto;
`


export const CartIconContainer = styled.div`
    width: 45px;
    height: 45px;
    margin-right:4px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`