import styled from 'styled-components';

export const MenuItemContainer = styled.div`
	// height: ${({ size }) => (size ? '380px' : '240px')};
	// min-width: 30%;
	// overflow: hidden;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color:white
	
	margin: 0 7.5px 15px;
	//overflow: hidden;
	&:hover {
        background-color:black
		cursor: pointer;
	
		& .content {
			opacity: 0.9;
		}
	}
	&:first-child {
    margin-right: 7.5px;
  }
  &:last-child {
    margin-left: 7.5px;
  }
`;



export const ContentContainer = styled.div`

  padding: 0 25px;
  margin-bottom:30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: #222222;
  opacity: 0.7;
  &:hover {
    background-color:black
    cursor: pointer;

    & .content {
        opacity: 0.9;
    }
}
 
`;

export const ContentTitle = styled.span`
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 18px;
  color: #ffffff;
`;

export const ContentSubtitle = styled.span`
  font-weight: lighter;
  font-size: 16px;
`;