import styled from 'styled-components';

export const CheckoutItemContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 150px;
  border-bottom: 1px solid darkgrey;
  padding-inline: 5px ;
line-height:23px;
  align-items: center;
  background-color:#ffffff;
`;

export const ImageContainer = styled.div`
  width: 33%;
  padding: 1px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const TextContainer = styled.span`
font-size:13px;
font-weight:600;
  
`;

export const QuantityContainer = styled(TextContainer)`
  display: flex;
  span {
    margin: 0 10px;
  }
  div {
    cursor: pointer;
  }
`;

export const RemoveButtonContainer = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;