import React from 'react'
import { Route } from "react-router-dom";
import CollectionPage from '../collection/collection.component'
//  import { firestore, convertCollectionsSnapshotToMap } from "../../firebase/firebase.utils";
import CollectionOverview from '../../components/collections-overview/collections-overview.component'
import { connect } from 'react-redux'
import { updateCollections } from "../../redux/shop/shop.actions";
import WithSpinner from "../../components/with-spinner/with-spinner.component";

import { useParams } from 'react-router';

export default function Shop({ history })  {

  const { id } = useParams();




   
   
        return (
        
  <section className="page_404">
	<div className="container">
		<div className="row">	
		<div className="col-sm-12 ">
		<div className="col-sm-10 col-sm-offset-1  text-center">
		<div className="four_zero_four_bg">
			<h1 className="text-center "></h1>
		
		
		</div>
		
		<div className="contant_box_404">
		<h3 className="h2">
		OOPS!
		</h3>
		
		<p>Network connection error. Please refresh the page!</p>
		
	</div>
		</div>
		</div>
		</div>
	</div>
</section>


          )
  
        
    }



