import React, { useEffect, useState } from 'react'
import { Route } from "react-router-dom";
import CollectionPage from '../collection/collection.component'
//  import { firestore, convertCollectionsSnapshotToMap } from "../../firebase/firebase.utils";
import CollectionOverview from '../../components/collections-overview/collections-overview.component'
import { connect } from 'react-redux'
import { updateCollections } from "../../redux/shop/shop.actions";
import WithSpinner from "../../components/with-spinner/with-spinner.component";

import { useParams } from 'react-router';
import {
  MenuItemContainer,
  BackgroundImageContainer,
  ContentContainer,
  ContentTitle,
  ContentSubtitle, OuterContainer, Heading, Text, SubHeading, CartIconContainer
} from './product.styles';
import { createStructuredSelector } from 'reselect';
import { addItem } from '../../redux/cart/cart.actions';


import { selectProductInfo } from '../../redux/products/products.selector';
import { getProductsbyProductId } from '../../redux/products/products.actions'
import Crousal from '../../components/crousal/crousal.component'
import { batch } from 'react-redux';
import { ReactComponent as Cart } from '../../assets/cart.svg'

const CollectionsOverviewWithSpinner = WithSpinner(CollectionOverview)
const CollectionPageWithSpinner = WithSpinner(CollectionPage)




function Product({ getbyProductId, updateCollections, productInfo, addItem }) {


  const { id } = useParams();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => { setIsReadMore(!isReadMore) };
  useEffect(() => {
    getbyProductId(id)
    console.log('productInfo', productInfo)


  }, [])



  return (
    <OuterContainer>{(productInfo && productInfo.length != 0) ? <div className="row">
      <Crousal items={productInfo.productImages} />


      <div className="col-md-6 col-sm-12" style={{ fontSize: 24 }}>
        <Heading style={{ marginTop: 20 }}>{productInfo.productname}</Heading>
        <SubHeading>₹{productInfo.pricePoint[0].price} <span style={{ textDecoration: 'line-through', color: 'rgb(126 126 126)', fontWeight: 400 }}>₹{(productInfo.pricePoint[0].price * ((100 + productInfo.discount) / 100)).toFixed(0)}</span> <span style={{ color: 'rgb(69 124 74)', fontWeight: 400 }}>{productInfo.pricePoint[0].price}({productInfo.discount}% OFF)</span></SubHeading>
        {/* ₹{item.pricePoint[0].price} <span style={{textDecoration:'line-through',color:'rgb(126 126 126)',fontWeight:400}}>₹{(item.pricePoint[0].price*((100+item.discount)/100)).toFixed(0)}</span> <span style={{color:'rgb(69 124 74)',fontWeight:400}}>({item.discount}% OFF)</span> */}
        <Text>{isReadMore ? productInfo.description.slice(0, 150) : productInfo.description}
          {productInfo.description.length > 150 &&
            <span style={{ color: 'black', fontSize: 14 }} onClick={toggleReadMore}>
              {isReadMore ? '...read more' : ' ...show less'}
            </span>
          }</Text>
        {/* <Text>{productInfo.description}</Text> */}

        <button class="row" style={{
          opacity: 0.7, width: 'fit-content',
          marginTop: '5px', backgroundColor: 'white',
          color: 'black',
          border: 'none',
          fontSize: '14px',
          borderRadius: '3px',
          // background-color: black;
          // color: white;
          position: 'sticky',
          bottom: 0,
          border: 'none'
        }} onClick={() => addItem(productInfo)} >
          Add to bag<CartIconContainer><Cart /></CartIconContainer>
        </button>
      </div>
    </div> : null}



    </OuterContainer>

  )


}





const mapStateToProps = createStructuredSelector({

  productInfo: selectProductInfo,

});

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getShopbyid:id => dispatch(getShopsbyshopid('61e40f73e4c33123a1a5a03a'))
//     }
//   }

const mapDispatchToProps = dispatch => ({
  getbyProductId: item => dispatch(getProductsbyProductId(item)),
  updateCollections: collectionMap => dispatch(updateCollections(collectionMap)),
  addItem: item => dispatch(addItem(item))

});

export default connect(mapStateToProps, mapDispatchToProps)(Product)
//export default connect(null, mapDispatchToProps)(Shop)