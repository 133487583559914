// import React from 'react';
import React from 'react';
import ReactDOM from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectStoresSections } from '../../redux/stores/stores.selector';
import CategoriesItem from '../categories/categories.component';
import { useDispatch, useSelector } from 'react-redux'

import MenuItem from '../menu-item/Menu-item.component';
import SimpleItem from '../simpleItem/simpleitem.component';

import BubblesItem from '../bubbles/bubbles.component';
import { getShops } from '../../redux/stores/stores.actions'


import {
  DirectoryMenuContainer, MenuItemContainer,
  BackgroundImageContainer,Outer,
  ContentContainer,
  ContentTitle,BubbleWrap,
  ContentSubtitle
} from './directory-state.styles';
import styles from './directory-state.module.css';
import { Card } from 'react-bootstrap';


function Directory() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
    const [searchTerm, setsearchTerm] = useState([]);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["capital", "name", "numericCode"]);
  const [filterParam, setFilterParam] = useState(["All"]);

  const sections = useSelector(x => x.store)
  useEffect(() => {
    // fetchOrganisation()
    setItems(sections.sections)
    
  },[])

  const filterResult=(store)=>{
    console.log(store)
    if(store=='All'){
      //return(sections.sections)
      setItems(sections.sections)
    }else{
      let filtered = sections.sections.filter((val)=>{
        if(val.store==store){
          console.log('store new',store)
          return val
        }
       
      })
     // console.log(filtered)
      setItems(filtered)
    }
   
    
  }
  console.log(sections.sections)

  const BubblesItem = ({ title, imageUrl, size, history, linkUrl, match, companyName }) => (
    <Outer>
   <MenuItemContainer
      size={size}
      onClick={() => filterResult(companyName)}
    >

      <ContentContainer className='content'>
        <ContentTitle>{companyName.toUpperCase()}</ContentTitle>

      </ContentContainer>
    </MenuItemContainer>
    </Outer>
 
  );
  if (sections.sections != []) {
    return (
      <div className={styles.outer}>

<BubbleWrap>
<Outer>
   <MenuItemContainer
      
      onClick={() => filterResult('All')}
    >

      <ContentContainer className='content'>
        <ContentTitle>All</ContentTitle>

      </ContentContainer>
    </MenuItemContainer>
    </Outer>
        {sections.sections.map(({ id, ...otherSectionProps }) => (

          <BubblesItem key={id} {...otherSectionProps} />
        ))}</BubbleWrap>
        <DirectoryMenuContainer>

          {items.map(({ id, ...otherSectionProps }) => (
            <SimpleItem key={id} {...otherSectionProps} />
          ))}
        </DirectoryMenuContainer>
      </div>
    )
  }

 
}

const mapDispatchToProps = (dispatch) => {
  return {
      getShops:dispatch(getShops())
  }
}



// function Directory() {
//     const [error, setError] = useState(null);
//     const [isLoaded, setIsLoaded] = useState(false);
//     const [items, setItems] = useState([]);
//     const [q, setQ] = useState("");
//     const [searchParam] = useState(["capital", "name", "numericCode"]);
//     const [filterParam, setFilterParam] = useState(["All"]);

//     useEffect(() => {
//         fetch("https://restcountries.com/v3.1/all")
//             .then((res) => res.json())
//             .then(
//                 (result) => {
//                     setIsLoaded(true);
//                     setItems(result);
//                 },
//                 (error) => {
//                     setIsLoaded(true);
//                     setError(error);
//                 }
//             );
//     }, []);

//     function search(items) {
//         return items.filter((item) => {
//             if (item.region == filterParam) {
//                 return searchParam.some((newItem) => {
//                     return (
//                         item[newItem]
//                             .toString()
//                             .toLowerCase()
//                             .indexOf(q.toLowerCase()) > -1
//                     );
//                 });
//             } else if (filterParam == "All") {

//                 return searchParam.some((newItem) => {
//                   if(item[newItem]){
//                     return (
//                       item[newItem]
//                           .toString()
//                           .toLowerCase()
//                           .indexOf(q.toLowerCase()) > -1
//                   );
//                   }

//                 });
//             }
//         });
//     }

//     if (error) {
//         return <>{error.message}</>;
//     } else if (!isLoaded) {
//         return <>loading...</>;
//     } else {
//         return (
//             <div className="wrapper">
//                 <div className="search-wrapper">
//                     <label htmlFor="search-form">
//                         <input
//                             type="search"
//                             name="search-form"
//                             id="search-form"
//                             className="search-input"
//                             placeholder="Search for..."
//                             value={q}
//                             onChange={(e) => setQ(e.target.value)}
//                         />
//                         <span className="sr-only">Search countries here</span>
//                     </label>

//                     <div className="select">
//                         <select
//                             onChange={(e) => {
//                                 setFilterParam(e.target.value);
//                             }}
//                             className="custom-select"
//                             aria-label="Filter Countries By Region"
//                         >
//                             <option value="All">Filter By Region</option>
//                             <option value="Africa">Africa</option>
//                             <option value="Americas">America</option>
//                             <option value="Asia">Asia</option>
//                             <option value="Europe">Europe</option>
//                             <option value="Oceania">Oceania</option>
//                         </select>
//                         <span className="focus"></span>
//                     </div>
//                 </div>
//                 <ul className="card-grid">
//                     {search(items).map((item) => (
//                         <li>
//                             <article className="card" key={item.id}>
//                                 <div className="card-image">
//                                     <img src={item.flag} alt={item.name} />
//                                 </div>
//                                 <div className="card-content">
//                                     <h2 className="card-name">{item.name}</h2>
//                                     <ol className="card-list">
//                                         <li>
//                                             population:{" "}
//                                             <span>{item.population}</span>
//                                         </li>
//                                         <li>
//                                             Region: <span>{item.region}</span>
//                                         </li>
//                                         <li>
//                                             Capital: <span>{item.capital}</span>
//                                         </li>
//                                     </ol>
//                                 </div>
//                             </article>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         );
//     }
// }

// ReactDOM.render(<App />, document.getElementById("root"));

export default connect(null,mapDispatchToProps)(Directory);

//export default Directory;
