import styled from 'styled-components';

export const DirectoryMenuContainer = styled.div`
  padding-inline: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
 
  overflow-x: hidden;
  overflow-y: auto;
  position:relative;
`;
export const Horiz = styled.div`
flex:1
`;
