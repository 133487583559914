const INITIAL_STATE = {
    sections: [{
        title: 'hats',
        imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
        id: 1,
        linkUrl: 'shop/hats',
        category:'Art And Craft'

    },
    
    {   category:'Wood Works',
        title: 'jackets',
        imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
        id: 2,
        linkUrl: 'shop/jackets'
    },
    {category:'HandLooms',
        title: 'sneakers',
        imageUrl: 'https://i.ibb.co/0jqHpnp/sneakers.png',
        id: 3,
        linkUrl: 'shop/sneakers'
    },
    {category:'Decoupage',
        title: 'womens',
        imageUrl: 'https://i.ibb.co/GCCdy8t/womens.png',
        size: 'large',
        id: 4,
        linkUrl: 'shop/womens'
    },
    {category:'Personalised',
        title: ' mens ',
        imageUrl: 'https://i.ibb.co/R70vBrQ/men.png',
        size: 'large',
        id: 5,
        linkUrl: 'shop/mens'
    },
    {category:'Fun Items',
    title: ' mens ',
    imageUrl: 'https://i.ibb.co/R70vBrQ/men.png',
    size: 'large',
    id: 5,
    linkUrl: 'shop/mens'
},
{
    title: 'hats',
    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
    id: 6,
    linkUrl: 'shop/hats',
    category:'Art And Craft'

},
{
    title: 'hats',
    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
    id: 7,
    linkUrl: 'shop/hats',
    category:'Art And Craft'

},
{
    title: 'hats',
    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
    id: 8,
    linkUrl: 'shop/hats',
    category:'Art And Craft'

},
{
    title: 'hats',
    imageUrl: 'https://i.ibb.co/cvpntL1/hats.png',
    id: 9,
    linkUrl: 'shop/hats',
    category:'Art And Craft'

},
{   category:'Wood Works',
        title: 'jackets',
        imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
        id: 10,
        linkUrl: 'shop/jackets'
    },
    {   category:'Wood Works',
    title: 'jackets',
    imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
    id: 11,
    linkUrl: 'shop/jackets'
},
{   category:'Wood Works',
        title: 'jackets',
        imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
        id: 12,
        linkUrl: 'shop/jackets'
    },
    {   category:'Wood Works',
    title: 'jackets',
    imageUrl: 'https://i.ibb.co/px2tCc3/jackets.png',
    id: 13,
    linkUrl: 'shop/jackets'
},]
}


const stateProductsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state
    }
}
export default stateProductsReducer