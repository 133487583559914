import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import './sidenav.css';
import List from 'rsuite/List';
import { ReactComponent as Back } from '../../assets/back.svg'
import { ReactComponent as Next } from '../../assets/next.svg'
import { useNavigate } from 'react-router-dom';



const MultilevelSideNav = ({ data, sideNavState, sideNavHandler }) => {

    const [currentMenus, setCurrentMenus] = useState(data);
    const [previousStack, setPreviousStack] = useState([]);
    const navigate = useNavigate();

    const renderMenuItems = data => {
       console.log(data)

         return data.map((item, index) =>
            (item.subcategoryType && item.subcategoryType.length) ? (
                <List.Item key={index} index={index}  className="row"
                    onClick={e => {
                        previousStack.push(data);
                        setPreviousStack(previousStack);
                        setCurrentMenus(item.subcategoryType)
                      
                    }} 
                    ><div style={{textAlign:'left'}} className="col-9">
 <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/category/'+item.img} alt='item' style={{width:35 ,height:35 ,borderRadius:5,marginInline:10}} />
                        {item.type}
                    </div>
                         <div style={{textAlign:'right'}} className="col"> <Next/></div></List.Item>

            ) :  <List.Item key={index} index={index} style={{textAlign:'left'}}   onClick={() => {
                window.location.href = `${'/subcategproduct/'+item._id}`;
              }}>
                 <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/category/'+item.img} alt='item' style={{width:35 ,height:35 ,borderRadius:5,marginInline:10}} />
            {item.subType?item.subType :item.type}
          </List.Item>
        )

    }

    return data && (
        <div style={{ width: (sideNavState) ? '100%' : '0' }} className="multilevelSideNav">
            {/* <div className="closebtn" onClick={e => sideNavHandler(false)}>&times;</div> */}
            {(previousStack.length) ?
                <div style={{textAlign:'left',marginBottom:10}} onClick={e => {
                    const prevState = previousStack.pop();
                    setPreviousStack(previousStack);
                    setCurrentMenus(prevState);

                }}><Back/> Back</div>
                : null
            }
            {
               renderMenuItems(currentMenus)
            }

        </div>
    );
}

export default MultilevelSideNav;