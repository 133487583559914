import React ,{useEffect} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectDirectorySections } from '../../redux/directory/directory.selector';
import { selectFeaturedProduct } from '../../redux/products/products.selector';
import { getFeaturedProducts } from '../../redux/products/products.actions';

import { useNavigate } from 'react-router-dom';
import SimpleItem from '../simpleItem/simpleitem.component';


import { DirectoryMenuContainer } from './recentlybuild.styles';

const Directory = ({ sections,getFeaturedProducts }) => { 
    console.log('sections',sections)
    const navigate = useNavigate();
    useEffect(() =>{
        getFeaturedProducts()
    },[])
    return(
   
    <DirectoryMenuContainer>
            <div className="headings" style={{color:'#5e5e5e'}}>Featured <span style={{marginLeft:'30vw',fontSize:14,fontStyle:'normal'}} onClick={() => navigate(`${'/featured'}`)} >Show All </span></div>

        {sections.slice(-6).map(({ ...otherSectionProps }) => (
            <SimpleItem {...otherSectionProps} />
        ))}<br/>
      
    </DirectoryMenuContainer>
)}
;

const mapStateToProps = createStructuredSelector({
    sections: selectFeaturedProduct
});

const mapDispatchToProps = dispatch => ({
    getFeaturedProducts: item => dispatch(getFeaturedProducts()),
   // getProductsbyshopid: item => dispatch(getProductsbyshopid(item)),

});

export default connect(mapStateToProps,mapDispatchToProps)(Directory);