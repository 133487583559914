import React,{useEffect} from 'react';



import { DirectoryMenuContainer,Heading,SubHeading,Text } from './footer.styles';

//import { useDispatch, useSelector } from "react-redux";

const Footer = () => {
    let categories =[]
    // useEffect(() => {

          
    //        const result = sections.reduce((sections, d) => {
    //         const found = sections.find(a => a.type === d.type);
    //         //const value = { name: d.name, val: d.value };
    //        ; // the element in data property
    //         if (!found) {
    //           console.log(d.type)
    //           sections.push({type:d.type, img:d.img}) // not found, so need to add data property
    //         }
    //         else {
    //           //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
    //           //found.data.push(value) // if found, that means data property exists, so just push new element to found.data.
    //         }
    //         return sections;
    //       }, []);
           
           
       
    //     console.log('sections',sections)
  
    // }, []);
    return(
    <DirectoryMenuContainer>
        <Heading>CraftBecho</Heading>
        <div className="row" style={{paddingInline: '10%',maxWidth:'100%'}}>
            <div className="col-md-4 col-sm-12">
                <SubHeading>Vision</SubHeading>
                <Text>Our Vision is “Giving Wings To Your Ideas”. We are making it possible by creating an ecosystem of “Artisans”, where modernisation and tech enhances our traditions, art, craft and utility to support the Artisans and make your world colourful and unique.</Text>      </div>
                <div className="col-md-4 col-sm-12">
                <SubHeading>About Us
</SubHeading>
                <Text> Team CraftBecho hails from Uttarakhand. Uttrakhand also known as Dev-Bhoomi(Abode of Gods) is filled with amazing cultures and traditions.

We all moved in pace with the modernisation, however we left those cultures and traditions to be limited to local region or many have become extinct.
To buy or view these Art and Craft items we all had to travel all the way to the local Mela (Uttraini-A Traditional Uttrakhand Local Fair)where we were always mesmerised in the colourful world.

Same stories of traditions, art, craft and culture are all across India and we as Team CraftBecho are on a mission to support the amazing handmade with love works and supporting as many Artisans we can along our Journey.

We are one stop store for all the handmade trendy, artistic, crafty and unique utility range and we deliver your unique Piece of Joy direct from the Artist to you with a ton of love and smiles along with it.
</Text>
                </div>
                {/* <div className="col-md-4 col-sm-12">
              </div> */}
        </div>
     
      
    </DirectoryMenuContainer>
)};



export default Footer;