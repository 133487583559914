// import React from 'react';
import React from 'react';
import ReactDOM from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectSubcategories } from '../../redux/category/category.selector';
import { selectSections } from '../../redux/category/category.selector';

import CategoriesItem from '../categories/categories.component';
import { useDispatch, useSelector } from 'react-redux'

import MenuItem from '../menu-item/Menu-item.component';
import SimpleItem from '../simpleItem/simpleitem.component';

import {BubblesItem} from '../bubbles/bubbles.component';
import { getSubcategoriesFormCAteg } from '../../redux/category/category.actions';

import ContentLoader, { Facebook } from 'react-content-loader'
import { useNavigate } from 'react-router-dom';

import {
  DirectoryMenuContainer, MenuItemContainer,
  BackgroundImageContainer,Outer,
  ContentContainer,
  ContentTitle,BubbleWrap,
  ContentSubtitle,Horiz,LoaderWrap
} from './directory-wives.styles';
import styles from './directory-wives.module.css';
import { Card } from 'react-bootstrap';



const Directory = ({ sections ,getSubcategoriesFormCAteg}) =>{
  const navigate = useNavigate();
  useEffect(async () =>{
    await getSubcategoriesFormCAteg('61f1b0a2189c6a103cbed96c')
    console.log('sections[[[[[[[[[]]]]]]]]',sections.splice(-6))
    //getFeaturedProducts()
},[])



  const MyLoader = () => {
    
    return(<>
<Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
   
        
    </>
    
  )}


  const BubblesItem = ({ subType, img,id }) => (
    
    <Outer  onClick={() => navigate(`${'/subcategproduct/'+id}`)}>

     <div className="container2">
        <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/category/'+img}></img>
        <div className="card2">
            <span className="text">{subType}</span><br/>
           
            <button>See More</button>
        </div>
    </div>

    </Outer>
 
  );

  if (sections.length != []) {
    return (
   
<BubbleWrap>

                    {/* <div className="sp-card-1">
                        <ul>
                            <li>
                                <div className="card-title">Trending Shoes For You</div>
                                <div className="sub-line">7 Colors in each Sport Pair</div>
                                <span className="off-label">UP TO 65% OFF</span>
                                <div className="return-line">15 Days Return and Replacement<br/>
                                    <span style="font-size: 10px">Image by  from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=153310"style="color: #fff">Pixabay</a></span></div>
                            </li>
                            <li>
                                <div>
                                <img src="https://www.dropbox.com/s/pq93246lri9nb7m/chucks-153310_640.png?raw=1"/></div>
                                <div className="price-start">Start From $35</div>                                
                            </li>
                        </ul>
                        <span className="shop-button">
                            <a href="#" title="Click for Shop Now">SHOP NOW</a>
                        </span>                        
                    </div>                    
                */}

        {sections.splice(-6).map(({  ...otherSectionProps }) => (

          <BubblesItem {...otherSectionProps} />
        ))}</BubbleWrap>
       
   
    )
  }else{
    return(<LoaderWrap><MyLoader/></LoaderWrap>)
  }

}





const mapStateToProps = createStructuredSelector({
  sections: selectSubcategories
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSubcategoriesFormCAteg:item => dispatch(getSubcategoriesFormCAteg(item))
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(Directory);
