import React from 'react';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
// import Directory from '../../components/directory/Directory.component'
// import DirectoryCategory from '../../components/directoryCategory/directoryCategory.component'
// import DirectoryState from '../../components/directory-state/directory-state.component'
// import DirectoryWives from '../../components/directory-wives/directory-wives.component'

// import RecentlyBuild from '../../components/recentlybuild/recentlybuild.component'
// import AboutUs from '../../components/aboutus/aboutus.component'
//import { selectCartItems, selectCartTotal ,selectCartMRP} from '../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { ReactComponent as Next } from '../../assets/next.svg'


import { useNavigate } from 'react-router-dom';
import { ReactComponent as Order } from '../../assets/order.svg'
import { ReactComponent as Location } from '../../assets/placeholder.svg'

import style from './account.modules.css'


function Accountpage({ currentUser }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="upperBlock">
        <div className="textLabel">{currentUser.name}</div>
        <div className="textLabel">{currentUser.email}</div>
      </div>
      <div className="lowerBlock">
        <div className="row">
          <div  className="divImg col-2">
            <img src={require('../../assets/received.png')} style={{ height: 25, width: 25 }} alt="fireSpot" />
          </div>
          <div className="col-9" >
            <div style={{textAlign:'left'}} className="headingLabel" onClick={() => { navigate('/orders') }}>Orders</div>
            <div style={{textAlign:'left'}} className="textLable">Track your orders</div>
            
            </div>
            <div className = "col-1">
            <Next style={{filter: 'invert(44%) sepia(0%) saturate(527%) hue-rotate(269deg) brightness(92%) contrast(82%)'}}/>
            </div>
          </div>
         

      </div>
      <div className="lowerBlock">
        <div className="row">
          <div  className="divImg col-2">
            <img src={require('../../assets/placeholder.png')}  style={{ height: 25, width: 25 }} alt="fireSpot" />
          </div>
          <div  onClick={() => { navigate('/address') }}className="col-9" >
            <div style={{textAlign:'left'}}  className="headingLabel">Addresses</div>
            <div style={{textAlign:'left'}} className="textLable">Save address for hassle free checkout </div>
            
            </div>
            <div className = "col-1">
            <Next style={{filter: 'invert(44%) sepia(0%) saturate(527%) hue-rotate(269deg) brightness(92%) contrast(82%)'}}/>
            </div>
          </div>
       

      </div>


    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,

})
export default connect(mapStateToProps)(Accountpage)
