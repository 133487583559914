
import {GET_STORES,GET_STORES_BY_ID} from "./stores.type";

const INITIAL_STATE = {
    sections: [],
    storeInformation:[]
}


const storesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

       
       
            case GET_STORES:
            return {
                ...state,
                sections: action.payload


            }
            case GET_STORES_BY_ID:
            return {
                ...state,
                storeInformation: action.payload
                


            }
        default:
            return state
    }

}
export default storesReducer