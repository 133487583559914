import React from 'react';
// import Directory from '../../components/directory/Directory.component'
// import DirectoryCategory from '../../components/directoryCategory/directoryCategory.component'
// import DirectoryState from '../../components/directory-state/directory-state.component'
// import DirectoryWives from '../../components/directory-wives/directory-wives.component'

// import RecentlyBuild from '../../components/recentlybuild/recentlybuild.component'
// import AboutUs from '../../components/aboutus/aboutus.component'

import { useNavigate } from 'react-router-dom';
//import styles from './addressess.modules.css'
import Button from '@mui/material/Button';
// import { useForm, Controller } from "react-hook-form";
//import { Checkbox, TextField } from "@material-ui/core";
// import styles from "./addressess.modules.styles.css";
import TextField from '@mui/material/TextField';
import { color } from '@mui/system';



export default function Addressesspage({ history }) {
  // const { register, handleSubmit, watch, formState: { errors } } = useForm();
  // const onSubmit = data => console.log(data);
  //   const navigate = useNavigate();
    return (
  <div style={{backgroundColor:'#fff'}}>Address Page
   
    {/* <form onSubmit={handleSubmit(onSubmit)}>
   
      <TextField
         InputLabelProps={{
          // classes: {
          //   root: classes.cssLabel,
          //   focused: classes.cssFocused,
          // },
        }}
          id="standard-textarea"
          label="Name *"
  
          placeholder="Name"
          multiline
          // color={errors.example?'error':'info'}
        //  color='error'
          variant="standard"
          {...register("example", { required: true })}
        />

      <input type="submit" />
    </form> */}
    
    </div>
  
   

    )
    
}
