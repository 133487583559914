import React ,{useEffect} from 'react'
import CartItem from '../cart-item/cart-item.component'
import { connect } from 'react-redux'
import { CartDropDownContainer, CartItemsContainer, EmptyCartMessage, StyledCustomButton } from "./cart-dropdown.styles";
// import { withRouter } from 'react-router-dom'

import { createStructuredSelector } from 'reselect'
import { selectCartItems } from '../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../redux/user/user.selector'

import { toggleCartHidden } from '../../redux/cart/cart.actions'
import {setCurrentUser} from '../../redux/user/user.actions'
import GoogleLogin from 'react-google-login';
import { useGoogleLogin } from 'react-google-login'

// import { GoogleLogin } from 'react-google-login';
const CartDropdown = ({ cartItems, history ,currentUser ,setCurrentUser,toggleCartHidden}) => {
//     useEffect(() =>{ 
// console.log('currentUser',currentUser)

//     },[currentUser])
    console.log(cartItems)

    return(
    <CartDropDownContainer>
        <CartItemsContainer>
            {
                cartItems.length ?
                    cartItems.map(cartItem => <CartItem key={cartItem._id} item={cartItem} />)
                    :
                    <EmptyCartMessage>Your Cart is empty</EmptyCartMessage>
            }
        </CartItemsContainer>

           
        
    </CartDropDownContainer>)}



const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems,
    currentUser:selectCurrentUser,
})
const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    //  toggleCartHidden : dispatch(toggleCartHidden()),
  })


export default connect(mapStateToProps ,mapDispatchToProps)(CartDropdown)