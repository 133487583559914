import React, { useEffect } from 'react'
import { Route, Link } from "react-router-dom";
import CollectionPage from '../collection/collection.component'
//  import { firestore, convertCollectionsSnapshotToMap } from "../../firebase/firebase.utils";
import CollectionOverview from '../../components/collections-overview/collections-overview.component'
import { connect } from 'react-redux'
import { updateCollections } from "../../redux/shop/shop.actions";
import WithSpinner from "../../components/with-spinner/with-spinner.component";
import Styles from "./categoryproduct.module.css"
import { useParams } from 'react-router';
import {
    MenuItemContainer,
    BackgroundImageContainer,
    ContentContainer,
    ContentTitle,
    ContentSubtitle, OuterContainer, Heading, Text ,Sticky
} from './categoryproduct.styles';
import 'rsuite/dist/rsuite.min.css';
import Drawer from 'rsuite/Drawer';
import { Button, RadioGroup, Radio, ButtonToolbar, IconButton } from 'rsuite';
import DirectoryCategory from '../../components/directoryCategory/directoryCategory.component'

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { createStructuredSelector } from 'reselect';
import { getShopsbyshopid } from '../../redux/stores/stores.actions'
import { getProductsbyCAteg } from '../../redux/products/products.actions'


import { selectSections } from '../../redux/category/category.selector';
import { selectStoreInfo } from '../../redux/stores/stores.selector';
import { selectProductbyStoreid } from '../../redux/products/products.selector';
import { ReactComponent as Filter } from '../../assets/filter.svg'
import { ReactComponent as Categ } from '../../assets/categ.svg'



const CollectionsOverviewWithSpinner = WithSpinner(CollectionOverview)
const CollectionPageWithSpinner = WithSpinner(CollectionPage)

var shopid;
function Shop({ getProductsbyCAteg,products}) {

 
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
    const { id } = useParams();
    const handleOpen = key => {
        setOpen(true);
        setPlacement(key);

    };

    useEffect(() => {
        
        getProductsbyCAteg(id)
        
    }, [])

    shopid = id





    return (
        <OuterContainer>
          
           
           

<nav class="sticky">
	<ul className="row" style={{padding:'5px'}} >
    <li className="col" onClick={() => handleOpen('left')}><Filter/></li>
    <li className="col"  onClick={() => handleOpen('left')}><Categ/></li>
	
	</ul>
</nav>


                {/* <Sticky>           

<nav className="sticky">
	<ul>

	</ul>
</nav></Sticky> */}

               
          
            {/* <BrowserView>
                <ButtonToolbar>

                    <IconButton onClick={() => handleOpen('left')}>
                        Bottom
                    </IconButton>
                </ButtonToolbar>
            </BrowserView> */}


            <Drawer placement={placement} open={open} backdrop={true} size={'xs'} onClose={() => setOpen(false)}>
                <Drawer.Header>
                    <Drawer.Title>Categories</Drawer.Title>

                </Drawer.Header>
                <Drawer.Body>
                    <DirectoryCategory />
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/checkout">Checkout</Link>
                        </li>
                        <li>
                            <Link to="/contactus">Contact Us</Link>
                        </li>
                    </ul>
                </Drawer.Body>
            </Drawer>
            {(products.length!=0)?<CollectionOverview  items = {products} /> :null}
        
            {/* <CollectionOverview  {...products} /> */}

        </OuterContainer>

    )


}


const mapStateToProps = createStructuredSelector({
    sections: selectSections,
    storeInfo: selectStoreInfo,
    products:selectProductbyStoreid
});

// const mapDispatchToProps = (dispatch) => {
//     return {
//         getShopbyid:id => dispatch(getShopsbyshopid('61e40f73e4c33123a1a5a03a'))
//     }
//   }

const mapDispatchToProps = dispatch => ({
   
    getProductsbyCAteg: item => dispatch(getProductsbyCAteg(item)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Shop)