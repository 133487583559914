import styled from 'styled-components';
import CustomButton from '../custom-button/custom-button.component';
import { ReactComponent as ShoppingIcon } from '../../assets/cart.svg'
export const ShoppingIconF = styled(ShoppingIcon)`
        width: 24px;
        height: 24px;
        `

        export const CartIconContainer = styled.div`
        width: 45px;
       
       // position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `
export const CollectionItemContainer = styled.div`
min-width: 43vw;
max-width: 42vw;
border-width:1px;
border-style: solid;
border-color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 320px;
  align-items: center;
  position: relative;
  
  &:hover {
    .image {
      opacity: 0.8;
    }
    button {
      opacity: 0.85;
      display: flex;
    }
  }
`;

export const AddButton = styled(CustomButton)`
  opacity: 0.7;
  margin-top:5px;
   position: absolute;
  // top: 305px;
 
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 5px;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const CollectionFooterContainer = styled.div`
  width: 90%;
  // height: 30%;
  text-align:left;
  margin-left:5px;
  // font-size: 18px;
`;

export const NameContainer = styled.span`
  width: 90%;
  margin-bottom: 5px;
  color:#4a4a4a
`;
export const TitleContainer = styled.span`
  width: 90%;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight:500;
`;

export const PriceContainer = styled.span`
  width: 10%;
  text-align: right;
`;