import React from 'react'
import StripeCheckout from 'react-stripe-checkout'
import axios from 'axios';
import Button from '@restart/ui/esm/Button';

export default function StripeButton({ price }) {
    const priceForStripe = price * 100
    const publishableKey = 'pk_test_51Hcr59If3Hn5BLfLNdteyaTLDzz6Sf4g9CMEK21WZm7BHjEXbVLl2uqXNK4b9Gex3GBZrZTiP2aqV5QkncN7uasa001eoP4pJr'

    const onToken = token => {
        console.log(token)
        alert("dummy payment successfull")
    }
    return (
        <Button onClick={()=>{
            createOrder()
        }}>Pay Now</Button>
    )
}

function createOrder() {

        return  axios.post("https://cr-be.craftbecho.com/v1/payment/create-order", {}).then(res => {
            console.log(res)
            window.location.href =res.data.paymentLink
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    
}