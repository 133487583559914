import React from 'react';
import Directory from '../../components/directory/Directory.component'
import DirectoryCategory from '../../components/directoryCategory/directoryCategory.component'
import DirectoryState from '../../components/directory-state/directory-state.component'
import DirectoryWives from '../../components/directory-wives/directory-wives.component'

import RecentlyBuild from '../../components/recentlybuild/recentlybuild.component'
import AboutUs from '../../components/aboutus/aboutus.component'

import { useNavigate } from 'react-router-dom';
import styles from './homepage.module.css'

import { HomePageContainer } from './homepage.styles'

export default function Homepage({ history }) {
    const navigate = useNavigate();
    return (
        <HomePageContainer>
            <DirectoryCategory />
            {/* <hr style={{    color: '#ffffff',
    margin: 0}}/>  */}
  
   
 
           
             {/* <hr style={{    color: '#ffffff',
    margin: 0}}/>  */}
           
            <RecentlyBuild />
            <div className="headings" >Customise me</div>
           
            <DirectoryWives />
            <div className="headings"> Stores Listed</div>
            
           
            <Directory />
            {/* <div className="headings">Creations By  Fauji Wives</div>
            <div> More</div>
           
            
            <DirectoryWives />
            <div className="headings"> Promoting State HAndicrafts</div>
          
            <DirectoryState /> */}
            
        </HomePageContainer>
    )
}
