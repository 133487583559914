import styled from 'styled-components';

export const DirectoryMenuContainer = styled.div`


min-height:500px;
text-align: center;
background-color:#ffefe8;
  @media (max-width: 992px) {

        width:100%;
        margin:0;
    
  }
`;
export const Heading = styled.div`

padding-block: 50px;
    font-size: 60px;
    line-height: 48px;
    font-family: fantasy;
    font-weight: 300;
    color:#5e5c62;
`;
export const SubHeading = styled.div`

padding-block: 50px;
    font-size: 44px;
    line-height: 48px;
    font-family: sans-serif;
    font-weight: 500;
    color:#5e5c62;
`;
export const Text = styled.div`


    font-size: 24px;
    text-align:left;
    line-height: 25px;
    font-family:sans-serif;
    font-weight: 300;
    color:#5e5c62;
`;

