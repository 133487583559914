import React ,{useEffect}from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectStoresSections } from '../../redux/stores/stores.selector';
import CategoriesItem from '../categories/categories.component';

import MenuItem from '../menu-item/Menu-item.component';

import { DirectoryMenuContainer,Horiz } from './directory.styles';
import { getShops } from '../../redux/stores/stores.actions'
import { useDispatch, useSelector } from "react-redux";
import ContentLoader, { Facebook } from 'react-content-loader'
const MyLoader = () => {
    
    return(<>
<Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
    <Horiz><ContentLoader viewBox="0 0 100 70"  backgroundColor={'hsl(189deg 21% 57%)'}
    foregroundColor={'#a9c2c5'}>
      {/* Only SVG shapes */}    
      <rect x="0" y="0" rx="5" ry="5" width="90" height="70" />
  
    </ContentLoader></Horiz>
   
        
    </>
    
  )}

const Directory = ({ sections }) =>{
    var points = new Array(6);
    
    const dispatch = useDispatch()
    useEffect(() => {
      
        sections = shuffle(sections)
        console.log('sections========================',sections)
  
    }, []);
    function shuffle(array) {
        console.log(array)
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
    // const getAllShops=async ()  =>{
        
    //     await dispatch(getShops()).then(val => {
    //         val.results.forEach(x=>{
    //             x.logoImg='https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/shop/'+x.logoImg
    //         })
            
          
    //     })
    //  } ;
   // getShops.apply()
   //getShops()
    return(
        <DirectoryMenuContainer>
            {/* {sections.map(({ id, ...otherSectionProps }) => (
                <CategoriesItem key={id} {...otherSectionProps} />
            ))}<br/> */}   

            {(shuffle(sections).length==0)?    < MyLoader/>:sections.map(({  ...otherSectionProps }) => (
                <MenuItem  {...otherSectionProps} />
            ))}

            {}
        </DirectoryMenuContainer>
    )
} ;

const mapStateToProps = createStructuredSelector({
    sections: selectStoresSections
});
const mapDispatchToProps = (dispatch) => {
    return {
        getShops:dispatch(getShops())
    }
  }
// const mapDispatchToProps = dispatch => ({
//     getShops: dispatch(getShops())
//   })

export default connect(mapStateToProps,mapDispatchToProps)(Directory);