
import {GET_CATEGORIES,GET_SUB_CATEGORIES_FROM_CATEG} from "./category.types";

const INITIAL_STATE = {
    categories: [],
    subcateg:[]
}


const storesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

       
       
            case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload


            }

            case GET_SUB_CATEGORIES_FROM_CATEG:
            return {
                ...state,
                subcateg: action.payload


            }
        default:
            return state
    }

}
export default storesReducer