import React , {useEffect} from 'react';
//import { withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
    MenuItemContainer,
    BackgroundImageContainer,
    ContentContainer,
    ContentTitle,
    ContentSubtitle,OuterContainer
} from './categories.styles';

const CategoriesItem = ({type,img,_id }) => {
    const navigate = useNavigate();
    console.log('_id',_id)
  

    return(
    <OuterContainer>
    <MenuItemContainer
        onClick={() => navigate(`${'/categproduct/'+_id}`)}
       
    >
        <BackgroundImageContainer
            className='background-image'
            imageUrl={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/category/'+img}
        />
      
        {/* <ContentContainer className='content'>
            <ContentTitle>{title.toUpperCase()}</ContentTitle>
            <ContentSubtitle>SHOP NOW</ContentSubtitle>
        </ContentContainer> */}
    </MenuItemContainer>
    <ContentContainer className='content'>
            <ContentTitle>{type.toUpperCase()}</ContentTitle>
           
        </ContentContainer>
    </OuterContainer>
    
)};

export default CategoriesItem;