import React from 'react';

import {
    MenuItemContainer,
    BackgroundImageContainer,
    ContentContainer,
    ContentTitle,
    ContentSubtitle
} from './simpleitem.styles';
import { useNavigate } from 'react-router-dom';

const MenuItem = ({ primaryImage,_id,pricePoint,discount,productname }) => {
    console.log(primaryImage)
    const navigate = useNavigate();

    return (
        <MenuItemContainer
      
      onClick={() => navigate(`${'/product/'+_id}`)}
  >
      <BackgroundImageContainer
          className='background-image'
          imageUrl={ 'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/productimages/'+primaryImage}
      />
<ContentContainer>
  <ContentTitle>{productname}</ContentTitle><br/>
  <ContentTitle>
      ₹ {pricePoint[0].price} <span style={{ textDecoration: 'line-through', color: 'rgb(126 126 126)', fontWeight: 400 ,paddingLeft:5}}>  ₹ {(pricePoint[0].price * ((100 + discount) / 100)).toFixed(0)}</span> <span style={{ color: 'rgb(69 124 74)', fontWeight: 400 }}>({discount}% off)</span>
     </ContentTitle> </ContentContainer>
  
  </MenuItemContainer>
  

)};

export default MenuItem;