import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CollectionPreview from '../collection-preview/Collection-preview.component';

import { selectCollectionsForPreview } from '../../redux/shop/shop.selector';

import { CollectionsOverviewContainer } from './collections-overview.styles';

const CollectionsOverview = ({ items }) =>{
    let sortedArray = []
    items.forEach(element => {
        let val = {title:element.categoryType,items:[]}
        let index = sortedArray.map(function(e) { return e.title; }).indexOf(val.title);
        if(index==-1){
            sortedArray.push(val)
            index = sortedArray.map(function(e) { return e.title; }).indexOf(val.title);
            sortedArray[index].items.push(element)
        } else{
            sortedArray[index].items.push(element)
        }
        
        

        
    });
    console.log(sortedArray)

    return(<CollectionsOverviewContainer>
            {sortedArray.map(({ id, ...otherCollectionProps }) => (
                <CollectionPreview key={id} {...otherCollectionProps} />
            ))}
        </CollectionsOverviewContainer>
    )
} ;

const mapStateToProps = createStructuredSelector({
    collections: selectCollectionsForPreview
});

export default CollectionsOverview;