import styled from 'styled-components';

export const DirectoryMenuContainer = styled.div`


min-height:500px;
text-align: center;
background-color:#ffefe8;
  @media (max-width: 992px) {

        width:100%;
       
    
  }
`;
export const Heading = styled.div`
margin-top:5px;
padding-block: 5px;
    font-size: 24px;
    line-height: 28px;
    font-family: fantasy;
    font-weight: 300;
    color:#5e5c62;
`;
export const SubHeading = styled.div`

padding-block: 2px;
    font-size: 20px;
    line-height: 28px;
    font-family: sans-serif;
    font-weight: 500;
    color:#5e5c62;
`;
export const Text = styled.div`


    font-size: 18px;
    text-align:justify;
    line-height: 25px;
    font-family:sans-serif;
    font-weight: 300;
    color:#5e5c62;
`;

