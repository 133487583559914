import React,{useEffect } from 'react';

import {
    MenuItemContainer,
    BackgroundImageContainer,
    ContentContainer,
    ContentTitle,
    ContentSubtitle
} from './menu-item.styles';
import { useNavigate } from 'react-router-dom';
// import {getShops } from '../../redux/stores/stores.actions'
import { useDispatch, useSelector } from "react-redux";

const MenuItem = ({ id, logoImg,companyName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        
       // getAllShops();
        console.log( logoImg, id,companyName)
    }, []);
 
    return(
    <MenuItemContainer
       
        onClick={() => navigate(`${'/shop/'+id}`)}
    >
        <BackgroundImageContainer
            className='background-image'
            imageUrl={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/shop/'+logoImg}
        />
        <ContentContainer className='content'>
            <ContentTitle>{companyName.toUpperCase()}</ContentTitle>
            <ContentSubtitle>SHOP NOW</ContentSubtitle>
        </ContentContainer>
    </MenuItemContainer>
)};

export default MenuItem;