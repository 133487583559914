import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCategories } from '../../redux/category/category.actions'

import { selectSections } from '../../redux/category/category.selector';
import CategoriesItem from '../categories/categories.component';


import { DirectoryMenuContainer } from './directoryCategory.styles';
import { ErrorPage } from '../../Pages/errorpage/errorpage.component'
import { useNavigate } from 'react-router-dom';

//import { useDispatch, useSelector } from "react-redux";

const Directory = ({ sections }) => {
    let categories =[]
    const navigate = useNavigate();
    // useEffect(() => {

          
    //        const result = sections.reduce((sections, d) => {
    //         const found = sections.find(a => a.type === d.type);
    //         //const value = { name: d.name, val: d.value };
    //        ; // the element in data property
    //         if (!found) {
    //           console.log(d.type)
    //           sections.push({type:d.type, img:d.img}) // not found, so need to add data property
    //         }
    //         else {
    //           //acc.push({ name: d.name, data: [{ value: d.value }, { count: d.count }] });
    //           //found.data.push(value) // if found, that means data property exists, so just push new element to found.data.
    //         }
    //         return sections;
    //       }, []);
           
           
       
    //     console.log('sections',sections)
  
    // }, []);
   if(sections=='Network Error'||sections=='Request failed with status code 404'||sections==null) {
    navigate(`${'error'}`)
    return null
   }else{
    return(
        <DirectoryMenuContainer>
            
            {sections.map(({  ...otherSectionProps }) => (
                <CategoriesItem  {...otherSectionProps} />
            ))}<br/>
          
        </DirectoryMenuContainer>
    )
   }

   
 };

const mapStateToProps = createStructuredSelector({
    sections: selectSections
});

const mapDispatchToProps = (dispatch) => {
    return {
        getCategories:dispatch(getCategories())
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Directory);