import React, { Component } from 'react';
import { connect } from 'react-redux'
import Styles from'./App.css';
import { Route, Routes, Navigate ,Link} from 'react-router-dom';


import Homepage from './Pages/homepage/Homepage.component'
import AllShopsPage from './Pages/allshops/allshops.component'

import ShopPage from './Pages/shop/Shop.component'
import CategoryproductComponent from './Pages/categoryproduct/categoryproduct.component';

import SubCategProductComponent from './Pages/subcategproduct/subcategproduct.component';
import FeaturedComponent from './Pages/featured/featured.component';


//import SignInAndSignUpPage from './Pages/sign-in-and-sign-up/sign-in-and-sign-up.component'
import CheckoutPage from './Pages/checkout/checkout.component'
import ProductPage from './Pages/product/product.component'
import AccountPage from './Pages/account/account.component'
import OrdersPage from './Pages/orders/orders.component'
import AddressPage from './Pages/addressess/addressess.component'





import Header from './components/header/header.component'
import Footer from './components/footer/footer.component'
import Errorpage from './Pages/errorpage/errorpage.component'
import 'rsuite/dist/rsuite.min.css';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import {Helmet} from "react-helmet";



//import { auth, createUserProfileDocument } from './firebase/firebase.utils'
// import { setCurrentUser } from './redux/user/user.actions'
// import { createStructuredSelector } from 'reselect'
// import { selectCurrentUser } from './redux/user/user.selector'
import mandala from './assets/mandala.svg';
import Drawer from 'rsuite/Drawer';

import { Button,RadioGroup,Radio,ButtonToolbar,IconButton} from 'rsuite';
import 'rsuite/styles/index.less';
const App = () => {
  
      const [size, setSize] = React.useState('m');
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();
  
    const handleOpen = key => {
      setOpen(true);
      setPlacement(key);
    };
  return( 
    <div className="multi-bg" style={{display: 'block'}} >

<MobileView>

 
  
   <div> <Header /></div>
  
      <Routes>
        <Route exact path='/' element={<Homepage/>} />
        <Route exact path='/shop/:id' element={<ShopPage/>} />
        <Route exact path='/shops' element={<AllShopsPage/>} />
        <Route exact path='/checkout' element={<CheckoutPage/>} />
        <Route exact path='/product/:id' element={<ProductPage/>} />
        <Route exact path='/categproduct/:id' element={<CategoryproductComponent/>} />
        <Route exact path='/account' element={<AccountPage/>} />

        <Route exact path='/subcategproduct/:id' element={<SubCategProductComponent/>} />
        <Route exact path='/featured' element={<FeaturedComponent/>} />
        <Route exact path='/address' element={<AddressPage/>} />
        <Route exact path='/orders' element={<OrdersPage/>} />

        <Route exact path='/error' element={<Errorpage/>} />

        {/* <Route path='/signin' render={() => this.props.currentUser ? (<Navigate to='/' />) : <SignInAndSignUpPage />} /> */}
      </Routes>
      <a
        href="https://wa.me/+91 9027527439"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
      
      <Footer/>
      </MobileView>

  

      
    </div>
  )
}
// class App extends Component {
  
//   unsubscribeFromAuth = null
//   // componentDidMount() {
//   //   // const [size, setSize] = React.useState('xs');
//   //   // const [open, setOpen] = React.useState(false);
//   //   // const [placement, setPlacement] = React.useState();
  
//   //   // const handleOpen = key => {
//   //   //   setOpen(true);
//   //   //   setPlacement(key);
//   //   // };
//   // }

//   render() {

  
//     return <div className="multi-bg" >
//       <Header />
//       <Routes>
//         <Route exact path='/' element={<Homepage/>} />
//         <Route exact path='/shop/:id' element={<ShopPage/>} />
//         <Route exact path='/checkout' element={<CheckoutPage/>} />
//         <Route exact path='/product/:id' element={<ProductPage/>} />
//         <Route exact path='/error' element={<Errorpage/>} />

//         {/* <Route path='/signin' render={() => this.props.currentUser ? (<Navigate to='/' />) : <SignInAndSignUpPage />} /> */}
//       </Routes>
//       <a
//         href="https://wa.me/8630339653"
//         class="whatsapp_float"
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <i class="fa fa-whatsapp whatsapp-icon"></i>
//       </a>
      
//       <Footer/>
//     </div>;
//   }
// }

export default (App);
