
import {GET_PRODUCTS_BY_SHOPID,GET_PRODUCTS_BY_PRODUCTID,GET_FEATURED_PRODUCTS ,GET_PRODUCTS_BY_SUBCATEG,GET_PRODUCTS_BY_CATEG} from "./products.types";

const INITIAL_STATE = {
    products: [],
    productInfo:[],
    featured:[]
    
}


const productsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

       
       
            case GET_FEATURED_PRODUCTS:
            return {
                ...state,
                featured: action.payload


            }
            case GET_PRODUCTS_BY_SHOPID:
            return {
                ...state,
                products: action.payload


            }
            case GET_PRODUCTS_BY_CATEG:
                return {
                    ...state,
                    products: action.payload
    
    
                }
                case GET_PRODUCTS_BY_SUBCATEG:
                    return {
                        ...state,
                        products: action.payload
        
        
                    }
            case GET_PRODUCTS_BY_PRODUCTID:
            return {
                ...state,
                productInfo: action.payload
                


            }
        default:
            return state
    }

}
export default productsReducer