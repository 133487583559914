import styled from 'styled-components';

export const OuterContainer = styled.div`
//width:auto;
//background-color:#ffffff;
display: inline-block;
// margin-right: 90.5px;
// 	// height: ${({ size }) => (size ? '380px' : '240px')};
// 	//min-width: 30%;
// 	overflow: hidden;
// 	flex: 1 1 auto;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
	
	
`;

export const MenuItemContainer = styled.div`
border-radius: 50%;
width: 150px;
height: 150px;
margin-right: 40.5px;
	// height: ${({ size }) => (size ? '380px' : '240px')};
	// min-width: 30%;
	overflow: hidden;
	flex: 1 1 auto;
	//display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid #fff;
	// margin: 0 7.5px 15px;
	// overflow: hidden;
	&:hover {
		cursor: pointer;
		& .background-image {
			transform: scale(1.9);
			transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
		}
		& .content {
			opacity: 0.9;
		}
	}
	&:first-child {
  
  }
  &:last-child {
    margin-left: 7.5px;
  }
  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
    margin-right: 7.5px;
  }
  
`;

export const BackgroundImageContainer = styled.div`
border-radius: 50%;
width: 150px;
height: 150px;
// // 
//   width: 100%;
//   height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
    border-radius:40px;
  }
`;

export const ContentContainer = styled.div`
  // height: 90px;
  // margin-top:20px;
 //  padding: 0 25px;
//   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  opacity: 0.7;
  @media (max-width: 992px) {
    // margin-top:5px;
    // height: 50px;
  }
  
`;

export const ContentTitle = styled.span`
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 16px;
  color: #fff;
  @media (max-width: 992px) {
    font-size: 10px;
    margin-bottom: 2px;
  }
`;

