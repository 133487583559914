import { createSelector } from 'reselect'

const selectCategories= state => state.category



export const selectSections = createSelector(
    [selectCategories],
    categories => categories.categories
)
export const selectSubcategories = createSelector(
    [selectCategories],
    categories => categories.subcateg
)