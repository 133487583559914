import {GET_CATEGORIES ,GET_SUB_CATEGORIES_FROM_CATEG} from "./category.types";

import axios from 'axios';




export function getCategories() {
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/category?limit=100", {
        }).then((res) => {
            
            dispatch({
                type: GET_CATEGORIES,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch((err) =>{
            dispatch({
                type: GET_CATEGORIES,
                payload: null,
            });
           
        })
       
    };
}
export function getSubcategoriesFormCAteg(subcateg) {
    console.log('here getting sub category')
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/sub-category/"+subcateg, {
        }).then((res) => {
            
            dispatch({
                type: GET_SUB_CATEGORIES_FROM_CATEG,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch((err) =>{
            dispatch({
                type: GET_SUB_CATEGORIES_FROM_CATEG,
                payload: null,
            });
           
        })
       
    };
}


