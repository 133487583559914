//import { ADD_SHOP,ADD_SHOP_IMAGE,DELETE_SHOP_IMAGE,GET_ALL_SHOPS } from "./shop.actionTypes";
import {GET_PRODUCTS_BY_SHOPID ,GET_PRODUCTS_BY_PRODUCTID ,GET_FEATURED_PRODUCTS ,GET_PRODUCTS_BY_SUBCATEG,GET_PRODUCTS_BY_CATEG} from "./products.types";

import axios from 'axios';


// export const setCurrentUser = user => ({
//     type: UserActionTypes.SET_CURRENT_USER,
//     payload: user
// })

export function getProductsbyshopid(shopId) {
    console.log('reached in api',shopId)
 
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/products/"+shopId, {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_PRODUCTS_BY_SHOPID,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}
export function getProductsbyCAteg(categ) {
   
 
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/products/p-categ/"+categ, {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_PRODUCTS_BY_SUBCATEG,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}
export function getProductsbySubCateg(subcateg) {
    
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/products/p-subcateg/"+subcateg, {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_PRODUCTS_BY_CATEG,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}


export function getFeaturedProducts() {
    console.log('reached in api')
 
    return function(dispatch) {
        return  axios.post("https://cr-be.craftbecho.com/v1/products/get-featured", {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_FEATURED_PRODUCTS,
                payload: res.data,
            });
           
        console.log(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}




export function getProductsbyProductId(productId) {
    console.log('reached in api',productId)
 
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/products/product-id/"+productId, {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_PRODUCTS_BY_PRODUCTID,
                payload: res.data[0],
            });
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}



