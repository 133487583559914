import styled from 'styled-components';

export const CollectionPreviewContainer = styled.div`
  
  flex-direction: column;
  margin-bottom: 30px;
`;

export const TitleContainer = styled.h1`
  font-size: 20px;
  margin-bottom: 25px;
  font-weight:300;
  text-align:left;
  font-style:cursive;
  cursor: pointer;
  color: #f0f4f5;
  &:hover {
    color: #ffffff;
  }
`;

export const PreviewContainer = styled.div`
//width: 100vw;
// height: 200vw;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;


margin-inline: 5px;
 flex-wrap: wrap;

`;