import { createSelector } from 'reselect'

const selectProducts= state => state.product



export const selectProductbyStoreid = createSelector(
    [selectProducts],
    products => products.products
)
export const selectProductInfo = createSelector(
    [selectProducts],
    products => products.productInfo
)
export const selectFeaturedProduct = createSelector(
    [selectProducts],
    products => products.featured
)
// export const selectStoreInfo = createSelector(
//     [selectProducts],
//     stores => stores.storeInformation
// )