import styled from 'styled-components';

export const OuterContainer = styled.div`
//width:auto;
padding-inline:530px;

//background-color:black;
@media (max-width: 992px) {
    padding-inline:20px;
  }
//background-color:#ffffff;
//display: inline-block;
// margin-right: 90.5px;
// 	// height: ${({ size }) => (size ? '380px' : '240px')};
// 	//min-width: 30%;
// 	overflow: hidden;
// 	flex: 1 1 auto;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
	
	
`;

export const MenuItemContainer = styled.div`
//margin:50px;
//border-radius: 50%;
width: 630px;
height: 500px;
margin-right: 40.5px;
	// height: ${({ size }) => (size ? '380px' : '240px')};
	// min-width: 30%;
	overflow: hidden;
	flex: 1 1 auto;
	//display: flex;
	align-items: center;
	justify-content: center;
	
	// margin: 0 7.5px 15px;
	// overflow: hidden;
	&:hover {
		cursor: pointer;
		& .background-image {
			transform: scale(1.9);
			transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
		}
		& .content {
			opacity: 0.9;
		}
	}
	&:first-child {
  
  }
  &:last-child {
    margin-left: 7.5px;
  }
  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
    margin-right: 7.5px;
  }
  
`;

export const BackgroundImageContainer = styled.div`
//border-radius: 50%;
width: 630px;
padding-inline:30px;
height: 500px;
// // 
//   width: 100%;
//   height: 100%;
  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
  @media (max-width: 992px) {
    width: 80px;
    height: 80px;
  }
`;

export const ContentContainer = styled.div`
  height: 90px;
  margin-top:20px;
 //  padding: 0 25px;
//   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
  opacity: 0.7;
  @media (max-width: 992px) {
    margin-top:5px;
    height: 50px;
  }
  
`;

export const ContentTitle = styled.span`
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 16px;
  color: #4a4a4a;
  @media (max-width: 992px) {
    font-size: 10px;
    margin-bottom: 2px;
  }
`;

export const Heading = styled.div`

font-weight: 500;
font-size: 25px;
color: #fdfdef;
//line-height: 22px;
margin-bottom: 4px;
text-align: left;
padding-bottom:1px;
font-family: 'Roboto';
    @media (max-width: 992px) {
        font-size: 25px;
      }
`;
export const SubHeading = styled.div`

    font-size: 20px;
    text-align:left;
    line-height: 28px;
    font-family: sans-serif;
    font-weight: 400;
    color:#5e5c62;
`;
export const Text = styled.div`

    padding-bottom:10px;
    font-size: 24px;
    text-align:left;
    line-height: 25px;
    font-family:sans-serif;
    font-weight: 300;
    color:#fdfdef;
    @media (max-width: 992px) {
        font-size: 20px;
      }
`;

export const CartIconContainer = styled.div`
width: 45px;

// position: relative;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
`