import React from 'react';
import { connect } from 'react-redux';

import { addItem } from '../../redux/cart/cart.actions';

import {
    CollectionItemContainer,
    CollectionFooterContainer,
    AddButton,
    BackgroundImage,
    NameContainer,
    PriceContainer,
    TitleContainer,ShoppingIconF,CartIconContainer
} from './collection-styles.styles';
import { ReactComponent as Logo } from '../../assets/cart.svg'


import { useNavigate } from 'react-router-dom';
import {Crousal} from '../crousal/crousal.component'
const CollectionItem = ({ item, addItem }) => {
    console.log('item',item)
    const { name, price, imageUrl } = item;
    const navigate = useNavigate();

    return (
        <CollectionItemContainer >
            <BackgroundImage   onClick={() => navigate(`${'/product/'+item._id}`)} className='image'  imageUrl={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/productimages/' + item.primaryImage} />
            <CollectionFooterContainer  >
               
                <TitleContainer  onClick={() => navigate(`${'/product/'+item._id}`)}> {item.shopName.toUpperCase()}</TitleContainer><br/>
                <NameContainer  onClick={() => navigate(`${'/product/'+item._id}`)}>{item.productname}</NameContainer><br/>
                <TitleContainer  onClick={() => navigate(`${'/product/'+item._id}`)}>₹{item.pricePoint[0].price} <span style={{textDecoration:'line-through',color:'rgb(126 126 126)',fontWeight:400}}>₹{(item.pricePoint[0].price*((100+item.discount)/100)).toFixed(0)}</span> <span style={{color:'rgb(69 124 74)',fontWeight:400}}>({item.discount}% OFF)</span></TitleContainer><br/>
               
                <button class="row" style={{ opacity: 0.7,width:'fit-content',
  marginTop:'5px',  backgroundColor: 'white',
  color: 'black',
  border: 'none',
  fontSize: '14px',
  borderRadius: '3px',
  // background-color: black;
  // color: white;
  border: 'none'}}onClick={() => addItem(item)} >
                Add to bag<CartIconContainer><Logo/></CartIconContainer>
      </button>
                {/* <NameContainer><div className="Stars" style={{'--rating': 3}} aria-label="Rating of this product is 2.3 out of 5."></div></NameContainer> */}

            </CollectionFooterContainer>
            
        </CollectionItemContainer>
    );
};

const mapDispatchToProps = dispatch => ({
    addItem: item => dispatch(addItem(item))
});

export default connect(
    null,
    mapDispatchToProps
)(CollectionItem);