import React, { useEffect ,useState} from 'react'
import { connect } from 'react-redux'

//import { auth } from '../../firebase/firebase.utils'
import { ReactComponent as Logo } from '../../assets/logofinal.svg'

import CartIcon from '../cart-icon/Cart-icon'
import CartDropdown from '../cart-dropdown/cart-dropdown.component'
import MultilevelSideNav from '../sidenav/sidenav.component'

import { HeaderContainer, LogoContainer, OptionsContainer, OptionLink, SearchLink, Line, StyledCustomButton ,CartIconContainer} from './header.styles'

import { createStructuredSelector } from 'reselect'
import { selectCartHidden } from '../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { selectSections } from '../../redux/category/category.selector'

import styles from './header.module.css';
import { FaSearch } from 'react-icons/fa';
import Drawer from 'rsuite/Drawer';
import { toggleCartHidden } from '../../redux/cart/cart.actions'
import { getCategories } from '../../redux/category/category.actions'

import { setCurrentUser } from '../../redux/user/user.actions'
import GoogleLogin from 'react-google-login';
import GoogleLogout from 'react-google-login';

import { useGoogleLogin } from 'react-google-login'
import { useNavigate } from 'react-router-dom';

import { Button, RadioGroup, Radio, ButtonToolbar, IconButton } from 'rsuite';
import 'rsuite/styles/index.less';
import { ReactComponent as User } from '../../assets/user.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import { ReactComponent as CategoryIcon } from '../../assets/categories.svg'
import List from 'rsuite/List';
import axios from 'axios';


// const styles = {
//     radioGroupLabel: {
//       padding: '8px 12px',
//       display: 'inline-block',
//       verticalAlign: 'middle'
//     }
//   };
function Header({ currentUser, hidden ,category,getCategories,setCurrentUser }) {
    const [size, setSize] = React.useState('m');
    const [open, setOpen] = React.useState(false);
    const [openCateg,setOpencateg]= React.useState(false);
    const [openSearch, setOpensearch] = React.useState(false);
    const [sideNavState,setSideNavState] = useState(true);
    const [index, setIndex] = useState(0);
    const [placement, setPlacement] = React.useState();
    const responseGoogle =async (response) => {
        console.log(response);
        currentUser = response.profileObj
        console.log(currentUser)
        currentUser.id = currentUser.googleId
        setCurrentUser(currentUser)
        await axios.post('https://cr-be.craftbecho.com/v1/users/add-web-user',{currentUser}).then(resp => {
            // console.log(resp.data[0])
          
         
             console.log(resp.data);
             //return pincode
           }).catch(err =>{console.log('rrrrrr',err)})

        console.log(response.profileObj);


    }
    const logout = () => {

        setCurrentUser(null)
      


    }
    const handleLoginFailure = error => {
        console.log("Login Failure ", error);

    }
    const navigate = useNavigate();
    console.log('category=====>',category)

    useEffect(() => {
        console.log('currentUser', currentUser)
        console.log('hidden', hidden)
        console.log('category=====>',category)
        const timer = () => {
            setIndex(prevIndex => {
              if(prevIndex === placeholderText.length - 1){
                return 0;
              } 
              return prevIndex + 1;
            })
          };
          setInterval(timer, 2000);
          
          //cleanup function in order clear the interval timer
          //when the component unmounts
          return () => { clearInterval(timer); }
      
        // if (hidden) {
        //     handleClose()
        // } else {
        //     handleOpen('bottom')
        // }
       



    }, [])


    const handleOpen = (key) => {
        console.log(hidden)

        //setOpen(true);
        setOpen(prevMovies => (true));

        setPlacement(key);
    };
    const handleOpenCategory = (key) =>{
        console.log(hidden)

        //setOpen(true);
        setOpencateg(prevMovies => (true));

        setPlacement(key);
    }
    const handleClose = () => {

        setOpen(false);

    };
    /////
    const [input, setInput] = useState('');
  const [countryListDefault, setCountryListDefault] = useState();
  const [countryList, setCountryList] = useState();
  const placeholderText = ["Search all trending crafts", "Search all personalised gifts", "Search homemade skin care products"];


  const updateInput = async (input) => {

      console.log(input,countryListDefault)
      if(input){
        await fetch('https://cr-be.craftbecho.com/v1/products/product/'+input)
        .then(response => response.json())
        .then(data => {
            if(data.code==500){
                setCountryList([]);
            }else{
                setCountryList(data);
            }
            console.log('gfet',data)
          
          //  setCountryList(data) 
          //  setCountryListDefault(data)
         })
        
      }else{
          setCountryList([])
      }
  
    //  const filtered = countryListDefault.filter(country => {
    //   return country.name.toLowerCase().includes(input.toLowerCase())
    //  })
     setInput(input);
    
  }

  const SearchBar = ({keyword}) => {
    const BarStyling = {width:"20rem",background:"#F2F1F9", border:"none", padding:"0.5rem"};
    return (
      <input 
       style={BarStyling}
       key="random1"
       value={keyword}
       placeholder={"search country"}
       onChange={(e) => updateInput(e.target.value)}
      />
    );
  }
    return (
        <div className={styles.outer}>
            <HeaderContainer className="header">
            <div  onClick={() => handleOpenCategory('left')} style={{filter:'invert(100%) sepia(11%) saturate(7493%) hue-rotate(178deg) brightness(101%) contrast(110%)',  top: 30}}> <CategoryIcon /></div>
                {/* <h1>Craft Bech0 </h1> */}
                <LogoContainer to="/">
                    <Logo className="logo" />
                </LogoContainer>
                <OptionsContainer>
            
                    {/* <OptionLink to="/shop">
                    SHOP
                </OptionLink>
                <OptionLink to="/shop">
                    CONTACT
                </OptionLink> */}
                    {/* {
                    currentUser ?
                        <OptionLink as='div' onClick={() => auth.signOut()}>
                            SIGN OUT
                        </OptionLink>
                        :
                        <OptionLink to="/signin">
                            SIGN IN
                        </OptionLink>
                } */}
                    <div className="row" style={{marginRight:'3px'}}> 
                    
                    
                    <CartIconContainer onClick={()=>{
                        setOpensearch(!openSearch)
                        console.log(openSearch)

                    }} className="filter_float" >  <Search /></CartIconContainer>
                    <CartIconContainer className="filter_float" > <User /></CartIconContainer>
                    <CartIconContainer  onClick={() => handleOpen('bottom')}>  <CartIcon /></CartIconContainer>
                    {/* <CartIconContainer  onClick={() => handleOpenCategory('bottom')} className="filter_float" > <User /></CartIconContainer> */}

                   
                    {/* <thead onClick={() => handleOpen('bottom')}>  
                       </thead>
                  */}
               
                    </div>

                    {/* onClick={() => handleOpen('bottom')} */}
                </OptionsContainer>
             
                <Drawer full placement={placement} open={open} backdrop={true} onClose={() => setOpen(false)}>
                    <Drawer.Header>
                        <Drawer.Title>Buy Now</Drawer.Title>

                    </Drawer.Header>
                    <Drawer.Body>
                        <CartDropdown />
                        {currentUser ? <StyledCustomButton onClick={() => {
                            setOpen(false);

                            navigate('/checkout');

                        }
                        }>GO TO CHECKOUT
                        </StyledCustomButton> : <GoogleLogin
                            render={renderProps => (
                                <StyledCustomButton onClick={renderProps.onClick} disabled={renderProps.disabled}>Sign in using google
                                </StyledCustomButton>

                            )}
                            clientId="882265228617-d15q0phhs5vh8kt61oc4jr6rkf9tet2q.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                        />}
                    </Drawer.Body>
                </Drawer>
                <Drawer full placement={placement} open={openCateg} backdrop={true} onClose={() => setOpencateg(false)}>
                    <Drawer.Header style={{backgroundColor:'#3f3947'}}> {currentUser ? 
                        <Drawer.Title style={{color:'#fff',textAlign:'left',fontWeight: 500}}>Hi {currentUser['givenName']}</Drawer.Title>:
                        <Drawer.Title style={{color:'#fff',textAlign:'left',fontWeight: 300,textTransform: 'uppercase'}}>
                            <GoogleLogin
                            render={renderProps => (
                                <div onClick={renderProps.onClick} disabled={renderProps.disabled}>Login. Sign up using google
                                </div>

                            )}
                            clientId="882265228617-d15q0phhs5vh8kt61oc4jr6rkf9tet2q.apps.googleusercontent.com"
                            buttonText="Login"
                            onSuccess={responseGoogle}
                            onFailure={handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                        /></Drawer.Title>}
                    </Drawer.Header>
                    <Drawer.Body style={{padding: 10}}>
                    {currentUser ?<div>
                    
                        <div style={{fontSize: 14,border: 'none',padding: 14 ,textAlign:'left'}} onClick={()=> {navigate('/orders')}}> Orders</div>
                        <div style={{fontSize: 14,border: 'none',padding: 14 ,textAlign:'left'}} onClick={()=> {navigate('/account')}}> Account</div>
                        <GoogleLogout  render={renderProps => (
                                <div onClick={renderProps.onClick} disabled={renderProps.disabled} style={{fontSize: 14,border: 'none',padding: 14 ,textAlign:'left'}}>Logout
                                </div>

                            )} 
                        clientId="1084402625474-0v6206l9qg9d3a0sa8iot0frt9j7mh5k.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={logout}
                       
                        cookiePolicy={'single_host_origin'}/> 
                        <hr/></div>:null
}   
                   
                    <MultilevelSideNav sideNavState={sideNavState} sideNavHandler={setSideNavState} data={category}  />
                  

                    </Drawer.Body>
                </Drawer>

            </HeaderContainer>
            {openSearch?<div className={styles.searchInputWrapper}>
                        <div className="row"></div>
                        <input className={styles.searchInput} type="text"  input={input} autoFocus={false} placeholder={placeholderText[index]}
       onChange={(e)=>{updateInput(e.target.value)}}>
                      
                        </input>
                        <div onClick={()=>{ setOpensearch(!openSearch)}} style={{marginLeft:'87vw',zIndex:100,top:-25,textAlign:'right',color:'#a7a7a7',width:20 ,position:'relative'}}>X</div>
                        {/* <CartIconContainer  onClick={()=>{
                            
                            setOpensearch(!openSearch)
                            console.log(openSearch)
    
                        }} className="filter_float" style={{height:25,width:25,
                            fontSize: 'smaller',
                            fontWeight: 200,
                            color: '#a0aba7'}} >X</CartIconContainer> */}
           
                          
      <CountryList countryList={countryList}/>


                    </div>:null}

           


        </div>

    )
}

const CountryList = ({countryList=[]}) => {
    const navigate = useNavigate();
    if(countryList.length!==0){
        console.log(countryList)
        return (
            <>
             <List hover style={{minHeight:50,maxHeight:350,overflow:'scroll'}}>
    {countryList.map((data, index) => (
      <List.Item  key={data._id} index={index}>
          <div className="row"  onClick={()=>{
navigate('/product/'+data.pid);
window.location.reload(); 
          }}  >
              {/* <div className="col-2"><img  style={{height:60,width:60,borderRadius:5,marginInline:20}} src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/productimages/' + data.primaryImage}></img></div> */}
              <div className="col-10">
                  <div style={{color:'rgb(145 145 145)' , textAlign:'left',marginInline:30}}>{data.products}</div>
                  {/* <div style={{color:'#686863' , textAlign:'left',marginInline:30,fontWeight:500,fontSize:16}}>₹{data.productPrice[0].price}</div> */}
                  </div>
              


      </div>
       
      </List.Item>
    ))}
  </List>
        
            </>
          );
    }else{
        return null
    }

  }

const mapStatetoProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    hidden: selectCartHidden,
    category:selectSections,
})
const mapDispatchToProps = (dispatch) => {
    return {
        getCategories:dispatch(getCategories()),
        setCurrentUser:item => dispatch(setCurrentUser(item))
    }
  }
export default connect(mapStatetoProps,mapDispatchToProps)(Header)