import React from 'react';
import { useNavigate } from 'react-router-dom';

import CollectionItem from '../collection-item/collection-item.component';

import {
    CollectionPreviewContainer,
    TitleContainer,
    PreviewContainer
} from './collection-preview.styles';

const CollectionPreview = ({ title, items }) =>{ 
    console.log('Item=======================>',items)
    const navigate = useNavigate();

    return (
    <CollectionPreviewContainer>
        <TitleContainer >
            {title.toUpperCase()}
        </TitleContainer>
        <PreviewContainer>
            {items
                .map(item => (
                    <CollectionItem key={item.id} item={item} />
                ))}
        </PreviewContainer>
    </CollectionPreviewContainer>
)};

export default CollectionPreview;