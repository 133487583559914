import styled from 'styled-components';

export const MenuItemContainer = styled.div`
	min-height:260px;
	min-width: 10%;
  max-width: 50%;
	overflow: hidden;
  border-radius:10px;
	flex: 1 1 auto;
	display: flex;
	// align-items: center;
	// justify-content: center;
	
	margin: 0 2.5px 5px;
	overflow: hidden;
  //box-shadow: 0 0 10px 2px #77665A;
	&:hover {
    //box-shadow: 0 0 10px 2px #77665A;

		cursor: pointer;
		& .background-image {
			// transform: scale(1.1);
			// transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
		}
		& .content {
			opacity: 0.9;
		}
	}
	&:first-child {
    margin-right: 7.5px;
  }
  &:last-child {
    margin-left: 7.5px;
  }
  @media (max-width: 992px) {

    height: 120px;
    min-width: 140px;

}
`;

export const BackgroundImageContainer = styled.div`
  width: 100%;
  height: 70%;
  border-radius:10px;

  background-size: cover;
  background-position: center;
  background-image: ${({ imageUrl }) => `url(${imageUrl})`};
`;

export const ContentContainer = styled.div`
  margin-top:106%;
 // height: 70px;
 
width:100%;
  border-radius:5px;
  padding: 0 5px;
  //display: flex;
  //flex-direction: column;
  align-items: end;
  justify-content:bottom;
  //border: 1px solid black;
  //background-color: white;
  font-weight:500;
  position: absolute;
  @media (max-width: 992px) {
text-align:left;
font-size: 3.2vw;
    //height: 30px;
   // width:100%;
   // display: flex;

}
`;

export const ContentTitle = styled.span`
  //font-weight: 400;
  margin-bottom: 6px;
 // font-size: 16px;
  color: #4a4a4a;
  @media (max-width:992px){
    //font-size: 10px;
  }
`;

