import React from 'react';
import { connect } from 'react-redux';

import {
    clearItemFromCart,
    addItem,
    removeItem
} from '../../redux/cart/cart.actions';

import {
    CheckoutItemContainer,
    ImageContainer,
    TextContainer,
    QuantityContainer,
    RemoveButtonContainer
} from './checkoutItem.styles';

const CheckoutItem = ({ cartItem, clearItem, addItem, removeItem }) => {
    const { productname,description, primaryImage, quantity, pricePoint ,shopName ,discount} = cartItem;
    return (
        <CheckoutItemContainer>
            <ImageContainer>
                <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/productimages/'+primaryImage} alt='item' />
            </ImageContainer>
            <div style={{    width: '57%',textAlign:'left',paddingInline:5}}>
            <TextContainer>{productname}</TextContainer><br/>
            <TextContainer style={{fontWeight:300}}>{description.slice(0,30)} ...</TextContainer><br/>
            <TextContainer style={{fontWeight:300,fontSize:11,color:'#747474'}}>Sold by:{shopName}</TextContainer>

            <QuantityContainer>Qty: 
                <div onClick={() => removeItem(cartItem)}>&#10094;</div>
                <span>{quantity}</span>
                <div onClick={() => addItem(cartItem)}>&#10095;</div>
            </QuantityContainer>
            <TextContainer>₹{pricePoint[0].price} <span style={{ textDecoration: 'line-through', color: 'rgb(126 126 126)', fontWeight: 400 }}>₹{(pricePoint[0].price * ((100 +discount) / 100)).toFixed(0)}</span> <span style={{ color: 'rgb(69 124 74)', fontWeight: 400 }}>({discount}% OFF)</span></TextContainer>

            </div>
            
            <RemoveButtonContainer onClick={() => clearItem(cartItem)}>
                &#10005;
      </RemoveButtonContainer>
        </CheckoutItemContainer>
    );
};

const mapDispatchToProps = dispatch => ({
    clearItem: item => dispatch(clearItemFromCart(item)),
    addItem: item => dispatch(addItem(item)),
    removeItem: item => dispatch(removeItem(item))
});

export default connect(
    null,
    mapDispatchToProps
)(CheckoutItem);