import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// import store from './redux/store'

import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './redux/store'
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.min.css';  
import {Helmet} from "react-helmet";



ReactDOM.render(
    <Provider store={store}>
        <Helmet>
                <meta charSet="utf-8" />
                <title>CrafBecho</title>
                <meta name="description" content="CraftBecho #art #handmade #painting #craft #online #shorts #shortvideo #share #reaction #diy #love."></meta>
            </Helmet>
        <BrowserRouter>
            <PersistGate persistor={persistor}>
                <App />
            </PersistGate>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'));

