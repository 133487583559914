//import { ADD_SHOP,ADD_SHOP_IMAGE,DELETE_SHOP_IMAGE,GET_ALL_SHOPS } from "./shop.actionTypes";
import {GET_STORES,GET_STORES_BY_ID} from "./stores.type";

import axios from 'axios';


// export const setCurrentUser = user => ({
//     type: UserActionTypes.SET_CURRENT_USER,
//     payload: user
// })

export function getShops() {
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/shop?limit=100", {
        }).then(res => {
            dispatch({
                type: GET_STORES,
                payload: res.data.results,
            });
           
           // resolve(res.data)
        })
       
    };
}
export function getShopsbyshopid(shopId) {
    console.log('reached in api',shopId)
 
    return function(dispatch) {
        return  axios.get("https://cr-be.craftbecho.com/v1/shop/"+shopId, {
        }).then(res => {
            console.log(res)
            dispatch({
                type: GET_STORES_BY_ID,
                payload: res.data,
            });
           
           // resolve(res.data)
        }).catch(err =>{
            console.log(err)
        })
       
    };
}
// export const getShops=async(dispatch) => {
//     return new Promise(async function (resolve, reject) {
//         axios.get("http://cr-be.craftbecho.com/v1/shop", {
//         }).then(res => {
//             dispatch({
//                 type: GET_STORES,
//                 payload: res.data.results,
//             });
           
//             resolve(res.data)
//         })
       

//     })
      
//     }
 const getallShops = ()  => {

 

      




}


