import React from 'react'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CheckoutItem from '../../components/checkout-item/checkout-item.component'
import StripeButton from '../../components/stripe-button/stripe-button.component'
import { selectCartItems, selectCartTotal ,selectCartMRP} from '../../redux/cart/cart.selectors'

import style from'./checkout.modules.css'
function CheckoutPage({ cartItems, total,mrp }) {
    console.log('cartItems',cartItems)
    return (
        <div className="checkout-page">
      

            {
                cartItems.map(cartItem =>
                    <CheckoutItem key={cartItem.id} cartItem={cartItem} />
                )
            }
            <div style={{width:'100vw',minHeight:150,backgroundColor:'rgb(251 244 244)',padding:15}}>
                <div style={{textAlign:'left',fontSize: 12,fontWeight: 600,textTransform:'uppercase'}}>Pricing details  ( {cartItems.length} items)</div>
                <hr/>
                <div className="row">
                    <div className="col" style={{textAlign:'left',fontSize:14}}>Total MRP</div>
                    <div className="col" style={{textAlign:'right',fontSize:14}}>₹ {(mrp).toFixed(2)}</div>

                </div>
                <div className="row">
                    <div className="col"  style={{textAlign:'left',fontSize:14}}>Discount on MRP</div>
                    <div className="col"  style={{textAlign:'right',fontSize:14 ,color:'#21a111'}}> - ₹ {(mrp - total).toFixed(2)}</div>

                </div>
                <hr/>
                <div className="row">
                    <div className="col"  style={{textAlign:'left',fontSize:14,fontWeight: 600}}>Total Amount</div>
                    <div className="col"  style={{textAlign:'right',fontSize:14,fontWeight: 600}}>₹{(total.toFixed(2))}</div>

                </div>

            </div>
       
           
            <StripeButton price={total} />
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    cartItems: selectCartItems,
    total: selectCartTotal,
    mrp:selectCartMRP,
})


export default connect(mapStateToProps)(CheckoutPage)
