import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './root-reducer'

const middlewares = []
const loggerMiddleware = createLogger();
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

export const store = createStore(rootReducer, applyMiddleware(  thunkMiddleware,
    loggerMiddleware))

export const persistor = persistStore(store)

export default { store, persistor }


// import { createStore ,applyMiddleware} from "redux";
// import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';

// import rootReducer from "./root-reducer";
// const loggerMiddleware = createLogger();

// export default createStore(rootReducer, applyMiddleware(
//     thunkMiddleware,
//     loggerMiddleware
// ));