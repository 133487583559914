import styled from 'styled-components';

export const DirectoryMenuContainer = styled.div`


overflow-x: scroll;
overflow-y: hidden;
white-space: nowrap;
  @media (max-width: 992px) {

         width:100%;
        margin:0;
    
  }
`;

