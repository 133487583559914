
export const GET_PRODUCTS_BY_SHOPID = "GET_PRODUCTS_BY_SHOPID";
export const  GET_PRODUCTS_BY_PRODUCTID = "GET_PRODUCTS_BY_PRODUCTID";
export const  GET_FEATURED_PRODUCTS = "GET_FEATURED_PRODUCTS";
export const GET_PRODUCTS_BY_CATEG = "GET_PRODUCTS_BY_CATEG"
export const GET_PRODUCTS_BY_SUBCATEG = "GET_PRODUCTS_BY_SUBCATEG"


// export const GET_STORES_BY_ID = "GET_STORES_BY_ID";




