import styled from 'styled-components'


export const HomePageContainer = styled.div`
    //display: flex;
   // flex-direction: column;
    //align-items: center;
    padding: 20px 220px;
   @media (max-width: 992px) {

    padding:0;

}
`