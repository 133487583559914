import { createSelector } from 'reselect'

const selectStores= state => state.store



export const selectStoresSections = createSelector(
    [selectStores],
    stores => stores.sections
)
export const selectStoreInfo = createSelector(
    [selectStores],
    stores => stores.storeInformation
)