import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player/youtube'


const Crousal  = ({items }) =>{ 
    
    if(items){
       items.forEach(element => {

        
       });
    }
    return (
       
       items? <Carousel showArrows={false} dynamicHeight={true} showIndicators={true} showThumbs={false} showStatus={false} > 
            {items.map( val=>( <div>
                 <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/productimages/'+val }   />
                
             </div>))}
            {/* <div>
                 <img src={'https://craftbechoimages.fra1.cdn.digitaloceanspaces.com/shop/34ca36f5-17e0-490b-a40a-212a423721d5.JPG' }   />
                
             </div> */}

           
        
        </Carousel>:null
    ); 
  

    };

export default Crousal;